import isEmpty from 'lodash/isEmpty';
import pluralise from 'pluralise';

import type { Address, Coordinates, Store } from '@jane/shared/models';

import { distance } from './location';
import { duration } from './timeUtils';

export function pluralizeDistance(miles: number) {
  return pluralise(miles, 'mile');
}

export function calculateDistance(
  userCoordinates: Coordinates | undefined,
  storeCoordinates: Coordinates
) {
  if (!userCoordinates || isEmpty(userCoordinates)) return 0;
  return parseFloat(distance(userCoordinates as Coordinates, storeCoordinates));
}

export function isLocationInDeliveryDistance(
  userCoordinates: Coordinates | undefined,
  store: Pick<Store, 'delivery_radius' | 'lat' | 'long'>
) {
  if (!userCoordinates) return true;
  if (store.delivery_radius == null) return true;

  const distance = calculateDistance(userCoordinates, store);
  return store.delivery_radius >= distance;
}

export function presentDistance(
  userCoordinates?: Coordinates,
  storeCoordinates?: Coordinates
) {
  if (
    !(
      userCoordinates &&
      userCoordinates.lat &&
      userCoordinates.long &&
      storeCoordinates &&
      storeCoordinates.lat &&
      storeCoordinates.long
    )
  )
    return '';

  const distance = calculateDistance(userCoordinates, storeCoordinates);

  return `${distance} ${pluralizeDistance(distance)} away`;
}

export const deliveryCoordinates = (
  deliveryAddress: Address | null
): Coordinates | undefined =>
  deliveryAddress && deliveryAddress.lat && deliveryAddress.lng
    ? {
        lat: deliveryAddress.lat,
        long: deliveryAddress.lng,
      }
    : undefined;

export const formatLastCallMinutes = (lastCallInterval: number) => {
  return `${lastCallInterval} min`;
};

export const formatLeadTimeMinutes = (maxLeadTimeMinutes: number) => {
  const days = duration(maxLeadTimeMinutes, 'minutes').days();
  const hours = duration(maxLeadTimeMinutes, 'minutes').hours();
  const minutes = duration(maxLeadTimeMinutes, 'minutes').minutes();

  const daysString = days > 0 ? `${days} days, ` : '';
  const hoursString = hours > 0 ? `${hours} hrs, ` : '';

  return `${daysString}${hoursString}${minutes} min`;
};

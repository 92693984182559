import haversine from 'haversine';

interface Point {
  lat: number;
  long: number;
}

export const distance = (point1: Point, point2: Point) => {
  if (
    !point1 ||
    !point2 ||
    point1.lat == null ||
    point1.long == null ||
    point2.lat == null ||
    point2.long == null
  ) {
    return '0';
  }

  const result = haversine(
    { latitude: point1.lat, longitude: point1.long },
    { latitude: point2.lat, longitude: point2.long },
    { unit: 'mile' }
  );

  return isNaN(result) ? '0' : result.toFixed(1);
};

import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';

import { request } from '../lib/request';

const brandUrl = (brandId: Id) => `${config.apiPath}/brands/${brandId}`;

export class BrandSource {
  static get(id: Id, params: any = {}) {
    return request(
      brandUrl(id),
      {
        method: 'GET',
      },
      params
    );
  }
}

import { config } from '@jane/shared/config';
import type { AppMode, Coordinates, Id } from '@jane/shared/models';

import { request } from '../lib/request';

export const StoresForProductSource = {
  get({
    id,
    coordinates,
    maxRadius,
    specialId,
    forceStoreVisibility,
    appMode,
  }: {
    appMode?: AppMode;
    coordinates?: Coordinates;
    forceStoreVisibility?: boolean | null;
    id: Id;
    maxRadius?: string | number | null;
    specialId?: Id | null;
  }) {
    const url = `${config.apiPath}/products/${id}/stores`;
    return request(
      url,
      { method: 'GET' },
      {
        ...coordinates,
        max_search_radius: maxRadius,
        special_id: specialId,
        is_brand_embed: appMode === 'brandEmbed',
        force_store_visibility: forceStoreVisibility,
      }
    );
  },
};

export const getCountryName = (acronym: string) => {
  const countryAcronym = acronym.toUpperCase();

  return Object.keys(countryList).find(
    (key: string) => countryList[key] === countryAcronym
  );
};

export const countryList: { [key: string]: string } = {
  canada: 'CA',
  'puerto rico': 'PR',
  'united states': 'US',
};
